'use client'

import clsx from 'clsx'
import styles from './nav-links.module.css'
import sharedStyles from '../../../../features/page-header/page-header.module.css'
import { PageHeaderSavedPageLink } from '../../../../features/page-header/page-header-saved-page-link'
import type { ReactNode } from 'react'
import { type PageHeader_QueryFragment } from '../../../../features/page-header/__generated__/page-header.gql'
import { PageHeaderSavedSearchesLink } from '../../../../features/page-header/page-header-saved-searches-link'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { list } from '../../config/shared-links'

type NavLinksProps = {
  marketTrendsUrl?: string | null
  location?: PageHeader_QueryFragment['location']
  mobileContent?: ReactNode
}

export const NavLinks = ({ mobileContent }: NavLinksProps) => {
  const isAgRedesign =
    useFeatureVariable<number>(['ag_header_redesign', 'version'], 0) > 0

  return (
    <>
      {!isAgRedesign && (
        <li>
          <PageHeaderSavedPageLink mobileContent={mobileContent} />
        </li>
      )}

      <li
        className={clsx(
          isAgRedesign
            ? styles.redesignedBusinessSolutions
            : styles.businessSolutions,
          {
            [sharedStyles.pageHeaderPipe]: !isAgRedesign,
          }
        )}
      >
        <a
          href="https://solutions.rent.com"
          data-tag_item="business_solutions"
          className={clsx(
            sharedStyles.pageHeaderLink,
            isAgRedesign && styles.navAnchor
          )}
        >
          {isAgRedesign ? 'Marketing Solutions' : 'Business Solutions'}
        </a>
      </li>

      <li
        className={clsx(
          isAgRedesign ? styles.redesignedListAProperty : styles.listAProperty,
          {
            [sharedStyles.pageHeaderPipe]: !isAgRedesign,
          }
        )}
      >
        <a
          href={list.URL}
          data-tag_item="list_a_property"
          className={clsx(
            sharedStyles.pageHeaderLink,
            isAgRedesign && styles.navAnchor
          )}
        >
          {list.TEXT}
        </a>
      </li>
      {isAgRedesign && (
        <>
          <li className={styles.savedSearches}>
            <PageHeaderSavedSearchesLink />
          </li>
          <li className={styles.savedProperties}>
            <PageHeaderSavedPageLink
              mobileContent={mobileContent}
              isAgRedesign
            />
          </li>
        </>
      )}
    </>
  )
}
