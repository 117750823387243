'use client'

import { forwardRef } from 'react'
import { PageHeader as DefaultPageHeader } from '../../../../features/page-header/page-header'
import type { PageHeaderProps } from '../../../../features/page-header/page-header'
import { AgPageHeader } from './ag-page-header'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  function PageHeader(props: PageHeaderProps, ref) {
    const isAgRedesign =
      useFeatureVariable<number>(['ag_header_redesign', 'version'], 0) > 0
    return isAgRedesign ? (
      <AgPageHeader {...props} ref={ref} />
    ) : (
      <DefaultPageHeader {...props} ref={ref} />
    )
  }
)
